import { render, staticRenderFns } from "./UpdateDoctor.vue?vue&type=template&id=86db4396&scoped=true"
import script from "./UpdateDoctor.vue?vue&type=script&lang=js"
export * from "./UpdateDoctor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86db4396",
  null
  
)

export default component.exports