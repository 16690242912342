<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="text-h6 grey lighten-2">
            <span class="headline">Actualizar doctor</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="10">
                  <v-text-field :loading="loadingData" v-model="editedItem.name"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" label="Nombre (*)"
                    required :rules="[vtextRulesNombre]" :error-messages="erroresNombre"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-checkbox v-model="editedItem.active" :disabled="saveChanges || loadingData" label="Activo"
                    v-if="user.roles=='ADMIN'"></v-checkbox>
                </v-col>


                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX' || user.roles === 'CEETSB'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_ceetsb" dense :items="['Aprobado', 'Pendiente', 'Rechazado']"
                      :rules="[v => !!v || 'Campo es obligatorio']" label="Excelencia" :loading="loadingData"
                      :disabled="saveChanges || user.roles === 'COPARMEX'" item-color="primary" persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX' || user.roles === 'CEETSB'" cols="7"
                  class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_ceetsb" :loading="loadingData"
                    :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges || user.roles === 'COPARMEX'" label="Comentario"
                    class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX' || user.roles === 'COEPRIS'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_coepris" dense :items="['Aprobado', 'Pendiente', 'Rechazado']"
                      :rules="[v => !!v || 'Campo es obligatorio']" :loading="loadingData" label="Repssabi"
                      :disabled="saveChanges || user.roles === 'COPARMEX'" item-color="primary" persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX' || user.roles === 'COEPRIS'" cols="7"
                  class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_coepris"
                    :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges || user.roles === 'COPARMEX'" :loading="loadingData"
                    label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_coparmex" dense :items="['Aprobado', 'Pendiente', 'Rechazado']"
                      :rules="[v => !!v || 'Campo es obligatorio']" label="Coparmex" :loading="loadingData"
                      :disabled="saveChanges" item-color="primary" persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="7"
                  class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_coparmex"
                    :disabled="editedItem.state_coparmex !== 'Rechazado' || saveChanges" :loading="loadingData"
                    label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col cols="12">
                  <v-radio-group v-model="editedItem.typeEntity">
                    <v-row>
                      <v-col cols="4">
                        <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                          label="Premium" value="P"></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                          label="Miembro" value="M"></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                          label="Básico" value="B"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.excellence" :disabled="saveChanges || loadingData"
                    v-if="user.roles == 'CEETSB' || user.roles == 'ADMIN'" label="Excelencia"></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.repsabi" :disabled="saveChanges || loadingData"
                    v-if="user.roles == 'COEPRIS' || user.roles == 'ADMIN'" label="Repssabi"></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.coparmex" :disabled="saveChanges || loadingData"
                    v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Coparmex"></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.promoted" :disabled="saveChanges"
                    v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Destacado"></v-checkbox>
                </v-col>
                <v-col cols="5">
                  <v-checkbox v-model="editedItem.certified_surgeon" :disabled="saveChanges"
                    v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Cirujano certificado"></v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-text-field dense v-model.number="editedItem.register_number"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :error="!editedItem.register_number" label="Número único de Identificación (*)"
                    :loading="loadingData" required>
                  </v-text-field>

                  <v-combobox v-model="editedItem.address" :items="places" :loading="loadingPlaces || loadingData"
                    :search-input.sync="searchQuery" no-data-text="No hay resultados de búsqueda!"
                    item-text="place_name" no-filter clearable label="Dirección de residencia (*)" required
                    :rules="[vtextRulesDireccion]" :error-messages="erroresDireccion" v-on="$listeners"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    v-on:change="getSelectedItem" @click:clear="onClear">
                  </v-combobox>
                  <v-text-field v-model="editedItem.email" label="E-mail" :rules="emailRules" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="editedItem.description" label="Descripción - Español (*)" required
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesDescripcion" no-resize rows="6"
                    :error="!editedItem.description" counter>
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="editedItem.description_english" label="Descripción - Inglés (*)" required
                    :rules="textRulesDescripcion" no-resize rows="6" :error="!editedItem.description_english"
                    :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" counter>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <!--v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.folio" type="number"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" :rules="[
                                          v=>!!v || 'Campo requerido',
                                          v=> v > 0 || 'El folio tiene que ser mayor que 0'
                                        ]" label="Folio"></v-text-field>
                </!--v-col-->
                <v-col cols="12" md="6" v-if="editedItem.typeEntity != 'B'">
                  <v-text-field dense v-model="editedItem.trial" type="date"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" label="Trial">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.record"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" label="Expediente">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field-simplemask v-model="editedItem.phone1" label="Teléfono MX (*)" v-bind:properties="{
                              dense: true,
                              prefix: '+52',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              loading:loadingData,
                              placeholder: '',
                              error: !editedItem.phone1,
                              rules: [v => !!v || 'Este campo es obligatorio!',
                                      v => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX'],
                              messageError: erroresTelefonoMexico
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" required />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field-simplemask v-model="editedItem.phone2" label="Teléfono USA" v-bind:properties="{
                              dense: true,
                              prefix: '+1',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              loading:loadingData,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              placeholder: '',
                              rules: textRulesTelefonoUSA
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" />
                </v-col>
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'ADMIN'">-->
                <!--                           <v-select v-model="editedItem.state" dense-->
                <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                <!--                                     label="Estado"-->
                <!--                                     :disabled="saveChanges"-->
                <!--                                     item-color="primary"-->
                <!--                                     persistent-hint>-->
                <!--                                    </v-select>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'ADMIN'">-->
                <!--                          <v-text-field dense v-model.number="editedItem.comment" :disabled="editedItem.state != 'Rechazado' || saveChanges"-->
                <!--                            label="Comentario"></v-text-field>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COPARMEX'">-->
                <!--                           <v-select v-model="editedItem.state_coparmex" dense-->
                <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                <!--                                     label="Estado"-->
                <!--                                     :disabled="saveChanges"-->
                <!--                                     item-color="primary"-->
                <!--                                     persistent-hint>-->
                <!--                                    </v-select>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COPARMEX'">-->
                <!--                          <v-text-field dense v-model.number="editedItem.comment_coparmex" :disabled="editedItem.state_coparmex != 'Rechazado' || saveChanges"-->
                <!--                            label="Comentario"></v-text-field>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'CEETSB'">-->
                <!--                           <v-select v-model="editedItem.state_ceetsb" dense-->
                <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                <!--                                     label="Estado"-->
                <!--                                     :disabled="saveChanges"-->
                <!--                                     item-color="primary"-->
                <!--                                     persistent-hint>-->
                <!--                                    </v-select>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'CEETSB'">-->
                <!--                          <v-text-field dense v-model.number="editedItem.comment_ceetsb" :disabled="editedItem.state_ceetsb != 'Rechazado' || saveChanges"-->
                <!--                            label="Comentario"></v-text-field>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COEPRIS'">-->
                <!--                           <v-select v-model="editedItem.state_coepris" dense-->
                <!--                                     :items="['Aprobado', 'Pendiente', 'Rechazado']"-->
                <!--                                     :rules="[v => !!v || 'Campo es obligatorio']"-->
                <!--                                     label="Estado"-->
                <!--                                     :disabled="saveChanges"-->
                <!--                                     item-color="primary"-->
                <!--                                     persistent-hint>-->
                <!--                                    </v-select>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" md="6" v-if="user.roles == 'COEPRIS'">-->
                <!--                          <v-text-field dense v-model.number="editedItem.comment_coepris" :disabled="editedItem.state_coepris != 'Rechazado' || saveChanges"-->
                <!--                            label="Comentario"></v-text-field>-->
                <!--                        </v-col>-->
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.web_page" label="Página Web"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesUrlWeb"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.profesional_license_number"
                    label="Número de Licencia Profesional (*)" required
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :error="!editedItem.profesional_license_number" :loading="loadingData"
                    :rules="rulesLicense"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.specialty_license_number"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Número de Licencia de la Especialidad" :loading="loadingData"
                    :rules="textRulesEspecialidad"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model.number="editedItem.certification_validity"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Número de Certificación Válida" :loading="loadingData"
                    :rules="textRulesCertificacion"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model.number="editedItem.certificate_number" label="Certificado Número"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesCertificacion"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model.number="editedItem.professional_license_verification"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :rules="textRulesLicenseProfessional" :loading="loadingData"
                    label="Verificación de Licencia Profesional"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model="editedItem.facebook_profile" label="Perfil en Facebook"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesFacebookUrl"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model="editedItem.twitter_profile" label="Cuenta de X"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesXUrl"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model="editedItem.instagram_profile" label="Perfil en Instagram"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesInstagramUrl"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field dense v-model="editedItem.tiktok" label="Perfil de Tiktok"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :rules="textRulesTiktokUrl"></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field dense v-model="editedItem.servicehours"
                    label="Horarios de Atención [hh:mm am/pm - hh:mm am/pm]" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                </v-col>
                <v-col col="12">
                  <v-select dense v-model="editedItem.specialties" :items="specialtiesList" item-text="name"
                    item-value="id" return-object label="Especialidades (*)" required multiple :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    item-color="primary" small-chips hint="Selecciones las especialidades del médico" persistent-hint
                    :rules="[vtextRulesEspecialidad]" :error-messages="erroresEspecialidad">
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select dense v-model="editedItem.gender" label="Género del Representante legal"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" :items="['Femenino', 'Masculino', 'Prefiero no decir', 'Otro']"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.rfc" label="RFC" maxlength="13" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.full_name"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="Nombre completo de la persona física o moral que presta o prestará el o los servicios"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.ret"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="Número de folio de Registro Estatal de Turismo (RET) de Baja California. (si aplica)"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.postal_code"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Código postal"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.colonia"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Colonia"></v-text-field>
                </v-col>
                <ProvinceMunicipality :row="false" @update-data="updateEmit" :saveChanges="saveChanges"
                  :entity="editedItem" :loadingData="loadingData" />
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.street"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Calle"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.num_ext" type="number"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Número exterior"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.num_int" type="number"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Número interior"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.whatsapp"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Whatsapp"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.extra_services"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Servicios extra"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select dense v-model="editedItem.speak_english"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="¿Cuenta con personal que hable ingés?"
                    :items="['Sí', 'No']"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select dense v-model="editedItem.english"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Proficiencia de inglés convencional"
                    :items="['Básico', 'Intermendio', 'Avanzado', 'Bilingüe']"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.professional_organism"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="Si es miembro vigente de algún organismo profesional estatal, nacional o internacional, favor de indicar el o los nombres"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select dense v-model="editedItem.integral_services"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="¿Ofrece algún servicio integral? es decir… transporte desde su arribo al aeropuerto, hospedaje y/o alimentación, adicional al tratamiento requerido"
                    :items="['Sí', 'No', 'No, pero me interesa hacer sinergía con los prestadores de servicios turísticos', 'No, pero me interesa hacer sinergía con los prestadores de servicios turísticos']"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.extra_services_tourism" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Si contestó que sé la pregunta anterior, favor de indicar el servicio turístico que ofrece adicional al tratamiento requerido"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.tourism_name" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Nombre comercial del servidor turístico con quien tiene la alianza comercial (si aplica)"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select dense v-model="editedItem.register_repssabi"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="Favor de indicar si cuenta con la constancia del Registro Estatal de establecimientos prestadores de servicios de salud y bienestar (REPSSABI) del la COEPRIS"
                    :items="['Sí', 'No', 'En trámite']"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.repssabi_folio" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Favor de indicar el número de folio de la constancia del REPSSABI de la COEPRIS"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.repssabi_expire_date" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Favor de indicar la fecha de vencimiento de la constancia de REPSSABI de la COEPRIS"
                    type="date"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.conacem_date"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData"
                    label="Favor de indicar la fecha de emisión de la constancia de la especialidad médica del solicitante (emitida por la CONACEM)"
                    type="date"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.conacem_expire_date" :loading="loadingData"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    label="Favor de indicar la fecha de vencimiento de la constancia de la especialidad médica del solicitante (emitida por la CONACEM)"
                    type="date"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <VideoAdd :videos="editedItem.videos" @updateVideo="updateVideo" action="UPDATE"></VideoAdd>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="saveChanges || loadingData" text @click="close">
              Cancelar
            </v-btn>
            <v-btn :disabled="!valid || loadingData" :loading="saveChanges" color="primary" @click="save">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import VideoAdd from "@/components/admin/modules/components/VideoAdd.vue";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import specialtiesService from "@/providers/SpecialtiesService";
import citiesService from "@/providers/CitiesService";
import doctorsService from "@/providers/DoctorsService";
import ProvinceMunicipality from "@/components/province-municipality/ProvinceMunicipality.vue";

export default {
  name: "UpdateDoctor",
  props: ["id"],
  components: {
    ProvinceMunicipality,
    VideoAdd
  },
  data(){
    return {
      erroresNombre: [],
      erroresNoIdentificacion: [],
      erroresDireccion: [],
      erroresTelefonoMexico: [],
      erroresCiudad: [],
      erroresLicencia: [],
      erroresEspecialidad: [],

      loadingData: true,

      textRulesNombre: [
        (v) => !!v || "Este campo es obligatorio!",
        (v) => /^[a-zA-Z .ñáéíóúÁÉÍÓÚüÜ]+$/.test(v) || "El campo debe contener solo letras, el espacio y el punto",
      ],
      textRulesNoIdentificacion: [
        (v) => !!v || "Este campo es obligatorio!",
        (v) => v === '' || /^\d{13}$/.test(v) || "El campo debe contener exactamente 13 dígitos numéricos",
      ],
      textRulesDireccion: [
        (v) => !!v || "Este campo es obligatorio!",
      ],
      textRulesDescripcion: [
        (v) => !!v || "Este campo es obligatorio!"
      ],
      textRulesUrlWeb: [
        (v) => v === '' || /^(https?:\/\/[^\s$.?#].[^\s]*)$/.test(v) || "El campo debe contener una URL válida que comience con http:// o https://",
      ],
      textRulesTelefonoMexico: [
        (v) => !!v || "Este campo es obligatorio!",
        (v) => v !== '+52' || "Este campo no puede ser solo '+52'.",
        (v) => /^(\+52\d{10})$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX.",
      ],
      textRulesTelefonoUSA: [
        (v) => !v || /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || "El campo debe contener un número de teléfono válido y seguir el formato +1XXXXXXXXXX.",
      ],
      textRulesLicencia: [
        (v) => {
          const isValidFormat = v === '' || /^[0-9]{1,8}?$/.test(v);
          return isValidFormat || "El número de licencia puede tener hasta 8 dígitos.";
        },
      ],
      textRulesCertificacion: [
        (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones',
        (v) => !v || v.toString().length <= 20 || 'El número de certificación no puede exceder los 20 caracteres'
      ],
      textRulesEspecialidad: [
        (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones'
      ],
      textRulesLicenseProfessional: [
        (v) => !v || /^[a-zA-Z0-9-]+$/.test(v) || 'El número de certificación puede tener letras, números o guiones'
      ],
      textRulesFacebookUrl: [
        (v) => {
          const isValidUrl = v === '' || /^https:\/\/www\.facebook\.com\/.+$/.test(v);
          return isValidUrl || "La URL de Facebook debe comenzar con https://www.facebook.com/ y contener un identificador válido.";
        },
      ],
      textRulesXUrl: [
        (v) => {
          const isValidUrl = v === '' || /^https:\/\/x\.com\/.+$/.test(v);
          return isValidUrl || "La URL de la Red X debe comenzar con https://x.com/ y contener un identificador válido.";
        },
      ],
      textRulesInstagramUrl: [
        (v) => {
          const isValidUrl = v === '' || /^https:\/\/www\.instagram\.com\/.+$/.test(v);
          return isValidUrl || "La URL de Instagram debe comenzar con https://www.instagram.com/ y contener un identificador válido.";
        },
      ],
      textRulesYoutubeUrl: [
        (v) => {
          const isValidUrl = v === '' || /^https:\/\/www\.youtube\.com\/.+$/.test(v);
          return isValidUrl || "La URL de Youtube debe comenzar con https://www.youtube.com/ y contener un identificador válido.";
        },
      ],
      textRulesTiktokUrl: [
        (v) => {
          const isValidUrl = v === '' || !v || /^https:\/\/www\.tiktok\.com\/.+$/.test(v);
          return isValidUrl || "La URL de Youtube debe comenzar con https://www.tiktok.com/ y contener un identificador válido.";
        },
      ],
      textRulesHorarioAtencion: [
        (v) => {
          if (!v || v == '') return true;
          const times = v.split(' - ');
          if (times.length !== 2) return "Formato hh:mm am/pm - hh:mm am/pm";
          const [startTime, endTime] = times.map(time => time.trim());
          const convertToMinutes = (time) => {
            let [hour, minutePart] = time.split(':');
            minutePart = minutePart.toLocaleUpperCase();
            let minutes = parseInt(hour) * 60 + parseInt(minutePart.slice(0, 2));
            if (minutePart.includes('PM') && hour != '12') {
              minutes += 720;
            }
            if (minutePart.includes('AM') && hour == '12') {
              minutes -= 720;
            }
            return minutes;
          };
          const startMinutes = convertToMinutes(startTime);
          const endMinutes = convertToMinutes(endTime);
          return startMinutes < endMinutes || "El horario de inicio debe ser anterior al horario de fin.";
        },
      ],
      textRules: [(v) => !!v || "Este campo es obligatorio!"],
      emailRules: [
        (v) => v === '' || !/[A-Z,Ñ]+/.test(v) || "No se permiten letras en mayúsculas",
        (v) => v === '' || /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ],
      imageRules: [
        (value) =>
            !value ||
            value.size < 2000000 ||
            "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
      ],
      rulesLicense: [
        v => !!v || 'Este campo es requerido',
        v => /^[a-zA-Z0-9-]+$/.test(v) || 'El número de licencia puede contener número, letras y guiones'
      ],
      editedItem: {
        id: 0,
        name: "",
        register_number: "",
        description: "",
        videos: [],
        description_english: "",
        excellence: false,
        typeEntity: 'M',
        repsabi: false,
        address: "",
        email: "",
        phone1: "",
        phone2: "",
        map_lat: 0.0,
        map_lng: 0.0,
        city_id: 0,
        web_page: "",
        profesional_license_number: "",
        specialty_license_number: "",
        certification_validity: "",
        certificate_number: "",
        professional_license_verification: "",
        facebook_profile: "",
        twitter_profile: "",
        instagram_profile: "",
        youtube_video: "",
        membership: 0,
        servicehours: "",
        translation_id: 0,
        pictures: [],
        associations: [],
        certifications: [],
        specialties: [],
        tiktok: "",
        sku: "",
        coparmex: false,
        state: 'Pendiente',
        whatsapp: "",
        extra_services: "",
        speak_english: "",
        english: "",
        professional_organism: "",
        integral_services: "",
        extra_services_tourism: "",
        tourism_name: "",
        repssabi_folio: "",
        repssabi_expire_date: "",
        conacem_date: "",
        conacem_expire_date: "",
        accept_term: false,
        comment: "",
        register_repssabi: "",
        gender: "",
        rfc: "",
        full_name: "",
        ret: "",
        postal_code: "",
        colonia: "",
        state_country: "",
        city_country: "",
        street: "",
        num_ext: null,
        num_int: null,
        comment_ceetsb: "",
        comment_coparmex: "",
        comment_coepris: "",
        state_ceetsb: "Pendiente",
        state_coparmex: "Pendiente",
        state_coepris: "Pendiente",
        folio: null,
        record: "",
        promoted: false,
        certified_surgeon: false,
        province: null,
        municipality: null,
        trial: "",
      },
      defaultItem: {
        id: 0,
        name: "",
        register_number: "",
        description: "",
        videos: [],
        description_english: "",
        excellence: false,
        typeEntity: 'M',
        repsabi: false,
        address: "",
        email: "",
        phone1: "",
        phone2: "",
        map_lat: 0.0,
        map_lng: 0.0,
        city_id: 0,
        web_page: "",
        profesional_license_number: "",
        specialty_license_number: "",
        certification_validity: "",
        certificate_number: "",
        professional_license_verification: "",
        facebook_profile: "",
        twitter_profile: "",
        instagram_profile: "",
        youtube_video: "",
        membership: 0,
        servicehours: "",
        translation_id: 0,
        pictures: [],
        associations: [],
        certifications: [],
        specialties: [],
        tiktok: "",
        sku: "",
        coparmex: false,
        state: 'Pendiente',
        whatsapp: "",
        extra_services: "",
        speak_english: "",
        english: "",
        professional_organism: "",
        integral_services: "",
        extra_services_tourism: "",
        tourism_name: "",
        repssabi_folio: "",
        repssabi_expire_date: "",
        conacem_date: "",
        conacem_expire_date: "",
        accept_term: false,
        comment: "",
        register_repssabi: "",
        gender: "",
        rfc: "",
        full_name: "",
        ret: "",
        postal_code: "",
        colonia: "",
        state_country: "",
        city_country: "",
        street: "",
        num_ext: null,
        num_int: null,
        comment_ceetsb: "",
        comment_coparmex: "",
        comment_coepris: "",
        state_ceetsb: "Pendiente",
        state_coparmex: "Pendiente",
        state_coepris: "Pendiente",
        folio: null,
        record: "",
        promoted: false,
        certified_surgeon: false,
        province: null,
        municipality: null,
        trial: "",
      },
      saveChanges: false,
      placeNameLimit: 60,
      selectedPlace: {
        place_name: "",
        center: [],
      },
      placesList: [],
      loadingPlaces: false,
      searchQuery: null,
      mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
      focus: false,
      snackBar: false,
      snackText: "",
      valid: false,
      associationsList: [],
      certificationsList: [],
      specialtiesList: [],
      doctors: [],
      cities: [],
      user: {},
      typeEntity: "member"
    }
  },
  computed: {
    places() {
      let listPlaces = [];
      if (this.placesList) {
        listPlaces = this.placesList.map((entry) => {
          const PlaceName =
              entry.place_name.length > this.placeNameLimit
                  ? entry.place_name.slice(0, this.placeNameLimit) + "..."
                  : entry.place_name;
          console.log(PlaceName);
          return Object.assign({}, entry);
        });
      }
      return listPlaces;
    },
  },
  watch: {
    searchQuery(val) {
      if (this.queryReady(val)) {
        this.searchPlaces(val);
      }
    },
  },
  methods: {
    updateEmit(value){
      this.editedItem = {
        ...this.editedItem,
        ...value
      }
    },
    close(){
      this.$router.push({
              name: 'DoctorsModule'
            });
    },
    updateVideo(videos){
      this.editedItem.videos = videos;
    },
    queryReady(val) {
      return val !== null && val !== undefined && val !== "";
    },
    onClear() {
      this.$emit("input", null);
      this.$emit("clear");
    },
    async searchPlaces(query) {
      if (query === null || query.length === 0) return;
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
      this.loadingPlaces = true;
      fetch(url)
          .then((res) => res.json())
          .then((res) => {
            const {query, features} = res;
            this.query = query;
            this.placesList = features;
          })
          .catch((err) => {
            console.log("Error ejecutando API: ", err);
          })
          .finally(() => (this.loadingPlaces = false));
    },
    getSelectedItem(item) {
      this.selectedPlace = Object.assign({}, item);
    },
    vtextRulesNombre(valNumero) {
      this.erroresNombre = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresNombre = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^[a-zA-Z .ñáéíóúÁÉÍÓÚüÜ]+$/.test(valNumero);
        this.erroresNombre = valid ? [] : ["El campo debe contener solo letras, el espacio y el punto"];
        return valid;
      }
      this.erroresNombre = [];
      return true;
    },
    vtextRulesDireccion(valNumero) {
      this.erroresDireccion = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresDireccion = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresDireccion = [];
      return true;
    },
    vtextRulesTelefonoMexico(valNumero) {
      this.erroresTelefonoMexico = [];
      if (valNumero !== null) {
        if (valNumero == "" || valNumero == "+52") {
          this.erroresTelefonoMexico = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^(\+52\d{10})$/.test(valNumero);
        this.erroresTelefonoMexico = valid ? [] : ["El campo debe contener un número de teléfono válido y seguir el formato +52XXXXXXXXXX"];
        return valid;
      }
      this.erroresTelefonoMexico = [];
      return true;
    },
    vtextRulesCiudad(valNumero) {
      this.erroresCiudad = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresCiudad = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresCiudad = [];
      return true;
    },
    vtextRulesLicencia(valNumero) {
      this.erroresLicencia = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresLicencia = ['Este campo es obligatorio!'];
          return false;
        }
        const valid = /^[a-zA-Z0-9-]+$/.test(valNumero);
        this.erroresLicencia = valid ? [] : ["El número de licencia puede tener hasta 8 dígitos"];
        return valid;
      }
      this.erroresLicencia = [];
      return true;
    },
    vtextRulesEspecialidad(valNumero) {
      this.erroresEspecialidad = [];
      if (valNumero !== null) {
        if (valNumero == "") {
          this.erroresEspecialidad = ['Este campo es obligatorio!'];
          return false;
        }
      }
      this.erroresEspecialidad = [];
      return true;
    },
    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0];
      const fechaActual = new Date().toISOString().split("T")[0];
      return fechaVencimiento > fechaActual;
    },
    isFechaCercanaTreintaDias(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      return diferenciaEnDias == 30;
    },
    isFechaCercanaQuinceDias(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      return diferenciaEnDias == 15;
    },
    changeCommentEdit(){
      const {state_coparmex, state_ceetsb, state_coepris} = this.editedItem;
      if(state_coparmex == 'Rechazado'){
        const commentCoparmex = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'COPARMEX');
        this.editedItem.comment_coparmex = commentCoparmex?commentCoparmex.comment:'';
      }
      if(state_ceetsb == 'Rechazado'){
        const commentCeetsb = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'CEETSB');
        this.editedItem.comment_ceetsb = commentCeetsb?commentCeetsb.comment:'';
      }
      if(state_coepris == 'Rechazado'){
        const commentCoepris = this.editedItem.changes_states.find(e=>e.state=='Rechazado' && e.type_role == 'REPSSABI');
        this.editedItem.comment_coepris = commentCoepris?commentCoepris.comment:'';
      }
    },
    save(){
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
          this.editedItem.address = this.selectedPlace.place_name;
          this.editedItem.map_lng = this.selectedPlace.center[0];
          this.editedItem.map_lat = this.selectedPlace.center[1];
        }
        doctorsService.updateRecord(this.editedItem).then((response) => {
            if (response.data.success) {
              this.snackText = "Registro guardado con éxito.";
              this.close();
            } else {
              this.snackText = response.data.message;
            }
          }).catch((err) => {
            console.log(err);
            this.snackText = "Ha ocurrido un error";
          }).finally(() => {
            this.snackBar = true;
            this.saveChanges = false;
          });
      }
    },
    getDoctor(){
      this.loadingData = true;
      doctorsService.getRecord(this.id).then(resp=>{
        const e = resp.value;
        this.editedItem = {
            ...e,
            trial: e.trial ? e.trial.substring(0, 10) :"",
            email: resp.value.email && e.email != null && e.email != "null" ? e.email : "",
            web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
            facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
            instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
            youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
            twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
          };
        this.changeCommentEdit();
      }).catch(()=>{
        this.snackText = 'Ha ocurrido un error al cargar los datos!';
        this.snackBar = true;
      }).finally(()=>{
        this.loadingData = false;
      });
    },
  },
  beforeCreate() {
    this.editedItem = Object.assign({}, this.defaultItem);
  },
  mounted(){
    this.user = this.$store.getters.getUser;
    changeInformationSEO();
    this.getDoctor();
    citiesService.getAllRecords().then((record) => {
      this.cities = record.value;
    });
    specialtiesService.getViewList().then((record) => {
      this.specialtiesList = record.value;
    });
  }
}
</script>

<style scoped>

</style>